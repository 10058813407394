import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

const Layout = styled.div`
width: 100%;
padding: 25px;

> div: first-child {
  width: 100%;
  max-width: 1246px;
  margin: auto;
}
`;

const Container = styled.div`
width: 100%;
display: flex;
flex-wrap: wrap;
`;

const Header = styled.div`
width: 100%;
display: flex;
background: white;
margin-bottom 10px;
height: 60px;
>div{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    .profile{
      width: 30px;
      height: 22px;
      margin: auto 10px auto 15px;
      >img{
        width: 100%;
        height: 100%;
      }
    }
    .count{
      font-family: Rubik-Medium;
      font-size: 18px;
      line-height: 24px;
      color:#0D4270;
      align-items: center;
      display: flex;
    }
  }

`;

const StyledModal = styled(Modal)`
@media (min-width: 576px){
  .modal-dialog {
    max-width:100% !important;
  }}
  .modal-dialog {
    transform: ${({transform}) => transform ? 'translate(0, 15vh) translate(0, -15%) !important' : 'translate(0, 50vh) translate(0, -50%) !important'};
    margin: ${({change}) => change ? '150px auto' : '0 auto'};
    // box-shadow: 0 5px 15px rgba(0,0,0,.5);
    @media (min-width: 576px){
      .modal-dialog {
        max-width:100% !important;
      }}
    .modal-content{
      border:none;
      box-shadow: 0 5px 15px rgba(0,0,0,.5);
    }
    @media (max-height: 460px) {
      transform: none !important;
      margin: 30px auto;
    }
    width: ${({width}) => width ? width : '490px'};
    height: ${({change, height}) => height? height :change ? '573px' : '473px'};
    @media (max-width: 500px){
      width: 95%;
      height: auto;
    }
  }

  .modal-body{
    padding: ${({padding}) => padding ? padding : '25px 0 25px 0'};
  }
  
  .modal-container {
   width: 100%;
   display: block;
   border: none;
   > div: nth-child(1) {
    width: 100%;
    height: 0px;
    display: flex;
    justify-content: flex-end;
    >img{
      width: 24px;
      height: 24px;
      cursor: pointer;
      margin: 15px 15px 0 0;
    }
   }
   > div: nth-child(2) {
    width: 100%;
    display:flex;
    justify-content: center;
    font-family: Rubik-Medium;
    font-size: 24px;
    line-height: 24px;
    color:#0D4270;
    text-decoration: underline;
    margin-top: 35px;
   }
  }
`;

const CalendarHeader = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  background: ${({style}) => style && style.background ? style.background : '#005C87'};
  border-bottom:${({style}) => style && style.borderBottom ? style.borderBottom : ''};
  border-radius: 6px 6px 0 0;
  > div: nth-child(1) {
    width:100%;
    font-family: Rubik-Medium;
    font-size: 18px;
    line-height: 24px;
    color:${({deleteInactiveUser}) => deleteInactiveUser ? '#005C87' : '#ffff'};
    margin: auto;
    display: flex;
    justify-content: center;
  }
 
`

const CalendarWrapper = styled.div`
  padding: ${({padding}) => padding ? padding : '0 25px'};
  .subtext{
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: #005C8799;
    padding:0 0 25px 0;
    text-align:center;
  }
  .textValue{
    color: #005C87;
    text-align: center;
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    display:flex;
    align-items:center;
  }
`

const ConfirmVisitText = styled.div`
  width: ${({deleteInactiveUser}) => deleteInactiveUser ?  '70%' : '100%'};
  font-family: ${({isTopData}) => isTopData ?  'Rubik' : 'Rubik-Medium'};
  ${'' /* font-family: Rubik-Medium; */}
  font-size: 18px;
  line-height: 24px;
  color:#005C87;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: ${({deleteInactiveUser,isTopData, margin}) => margin?margin: deleteInactiveUser ?  ' 25px auto 25px auto' : isTopData? '30px 0px 20px 0px' : ' 25px 0px 25px 0px'};
  >span{
    display: contents;
    font-family: Rubik-Medium;
    font-size: 18px;
    line-height: 24px;
    color:#005C87;
  }
  .note{
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: #005C8799;
  }
`
const DividerLine = styled.div`
    width: 752px;
    height: 0px;
    border: 1px solid rgba(0, 92, 135, 0.10);
`;

const TableHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0px 12px 0px;
    color: rgba(0, 92, 135, 0.60);
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
`;

const TableData = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0px 12px 0px;
    color: #005C87;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
`;



const ButtonContainer = styled.div`
  width: 100%;
  justify-content: space-between;
  margin: auto;
  display: flex;
  flex-wrap:wrap;
`;

const ButtonContainerV1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px; 
`;

const Button = styled.div`
  width: ${({width}) => width ? width : '205px'};
  height: ${({height}) => height ? height : '50px'};
  background: ${({background}) => background ? background : 'rgba(253, 113, 117, 1)'};
  font-family: Rubik-Medium;
  font-size: 18px;
  line-height: 24px;
  color: ${({color}) => color ? color : '#fff'};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 6px
  border: ${({border}) => border ? "1px solid rgba(156, 156, 156, 1)" : 'none'};
`;

const ButtonV2 = styled.div`
  width: ${({width}) => width ? width : '205px'};
  height: ${({height}) => height ? height : '50px'};
  background: ${({background}) => background ? background : 'rgba(253, 113, 117, 1)'};
  margin: ${({margin}) => margin ? margin : ''};
  font-family: Rubik-Medium;
  font-size: 18px;
  line-height: 24px;
  color: ${({color}) => color ? color : '#fff'};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 6px
  border: ${({border}) => border ? "1px solid #007AB1" : 'none'};
  :hover {
    background: ${({backgroundHover}) => backgroundHover ? backgroundHover : ''};
    color: ${({colorHover}) => colorHover ? colorHover : ''};
  }
`;


const NoData = styled.div`
  width: 100%;
  font-size: 16px;
  font-family: Rubik-Bold;
`;

const Backdrop = styled.div`
  width: 100%;
  height: 0px;
  position: relative;
  bottom: 175px;
  >div{
    width: 100%;
    height: 700px;
    background: transparent;
    position: relative;
  }
`;

const GridCard = styled.div`
width: 100%;
min-height: 330px;
/* cursor: pointer; */
border-radius: 6px;
.wellness-champion{
  width: 100%;
  height: 20px;
  margin-bottom: 5px;

  >div{
  width: 100%;
  background: linear-gradient(90deg, rgb(0, 177, 188) -1.11%, rgb(117, 217, 163) 97.46%);
  background: ${({deleted}) => deleted ? '#FD7175' :'linear-gradient(90deg, rgb(0, 177, 188) -1.11%, rgb(117, 217, 163) 97.46%)'};
  margin-bottom: 5px;
  display: flex;
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 6px 6px 0 0;
  }
}
.menu-button{
  width:100%;
  height: 0px;
  display: flex;
  justify-content: flex-end;
  >img{
    height: 6px;
    width: 24px;;
    cursor: pointer;
    margin-right: 10px;
    margin-top: -8px;
  }
}
.remove-button{
  width: 100%;
  height: 0px;
  display: flex;
  justify-content: flex-end;
  >div{
    width:118px;
    height:40px;
    background: white;
    border-radius: 6px;
    filter: drop-shadow(rgba(58, 58, 58, 0.2) 5px 4px 10px);

  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color:#0D4270;
  display: flex;
  justify-content:center;
  align-items: center;
  margin: 10px 10px 0 0;
  }
}
.profile{
  width: 100%;
  margin-bottom 10px;
  >div{
    width: 90px;
    height: 90px;
    margin: auto;
    >img{
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
  }
}
.name{
  font-family: Rubik-Medium;
  font-size: 18px;
  line-height: 24px;
  color:#0D4270;
  width: 100%;
  display: flex;
  margin-bottom 5px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.department{
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color:#9c9c9c;
  width: 100%;
  display: flex;
  margin-bottom 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.no-department{
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color:#0D4270;
  width: 100%;
  display: flex;
  margin-bottom 10px;
  justify-content: center;
  align-items: center;
}
.wellness-card{
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  justify-content: center;
  >div{
    width: 32px;
    height: 32px;
    display: flex;
    margin-right: 5px;
    background: linear-gradient(360deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%);
    border-radius: 6px;
    >img{
      width: 20px;
      height: 20px;
      margin: auto;
    }
    .hover-card{
      width: 0px;
      height: 0px;
      position: relative;
      display: block;
      >div{
        min-width: 90px;
        max-width: 120px;
        min-height: 25px;
        position: relative;
        right: 50px;
        bottom: 27px;
        background: rgba(156, 156, 156, 0.79);

        font-family: rubik;
        font-size: 12px;
        line-height: 12px;
        color: white;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        border-radius: 6px;
        padding: 5px 7px;
      }
    }
  }
}
.mutual-buddies{
  width: 100%;
  background: #F3F6F9;
  >div{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 50px;
    .text{
      font-family: Rubik;
      font-size: 14px;
      line-height: 20px;
      align-items: center;
      margin: auto 0px;
      margin-right: 10px;
      color: #0D4270;
    }
    .no-text{
      font-family: Rubik;
      font-size: 14px;
      line-height: 20px;
      align-items: center;
      margin: auto 0px;
      margin-right: 10px;
      color: #0D4270;
    }
    .profile-image-card{
      display: flex;
      margin-left: 12px;
      >img{
        width: 34px;
        height: 34px;
        margin: auto 0px;
        border-radius: 50%;
        margin-left: -12px;
        border: 2px solid white;
      }
      .count{
        display: 100%;
        background: linear-gradient(360deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%);
        font-family: Rubik;
        font-size: 12px;
        color: #fff;
        padding: 0px 8px;
        height: 34px;
        margin: auto;
        border-radius: 15px;
        margin-left: -12px;
        align-items: center;
        text-align: center;
        display: flex;
        border: 2px solid white;
      }
    }
  }
}
.button-cotainer{
  width: 100%;
  margin: 0px auto;
  padding: 10px 0px;
}
`;

const NoBuddy = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-container: center;
  .greyCircle{
    width: 341px;
    height: 341px;
    border-radius: 50%;
    background: rgba(156, 156, 156, 0.3);
    margin-top: 100px;
  }
  .nobuddyInfo{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #0D4270; 
    margin-top: 40px;
    margin-bottom: 35px;  
  }
  .goTo-Community{
    width: 320px;
    height: 50px;
    background: #69C2FF;
    border-radius: 3px;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    border: none;
  }
`;

export {Layout, Container, Header, CalendarWrapper, ButtonContainer,ButtonContainerV1, ConfirmVisitText, CalendarHeader, StyledModal, Button, NoData, Backdrop, DividerLine , TableHeader, TableData, GridCard, NoBuddy, ButtonV2};